jQuery(document).ready(function() {
    // Improves Bootstrap toggle buttons so the text can change on the button after a click.
    // For example:
    //     - default text is 'Show more...'
    //     - on click change to 'Show less'
    //     - click again, change to 'Show more...' again
    //
    // Expects HTML like:
    // <div id="target">
    //     ...
    // </div>
    // <a href="#target" data-toggle="collapse" data-toggle-secondary="Show less">Show more...</a>
    const toggleText = function() {
        jQuery("[data-toggle-secondary]").each(function() {
            let toggle = jQuery(this);
            let originalText = toggle.text();
            let secondaryText = toggle.data("toggle-secondary");
            let targetToggle = jQuery(toggle.attr("href"));

            targetToggle.on("show.bs.collapse hide.bs.collapse", function() {
                if (toggle.text() == originalText) {
                    toggle.text(secondaryText);
                } else {
                    toggle.text(originalText);
                }
            });
        });
    };

    toggleText();
});
window.addEventListener("scroll", () => {
    const navBar = document.getElementById("mainNavigation");
    let navBarHeight = navBar.getBoundingClientRect().height;
    if (
        document.documentElement.scrollTop &&
        document.documentElement.scrollTop >= navBarHeight
    ) {
        navBar.classList.add("is-scrolled");
    } else {
        if (navBar.classList.contains("is-scrolled")) {
            navBar.classList.remove("is-scrolled");
        }
    }
}, {
    passive: true
});
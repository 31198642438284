$(document).on("click", "[data-action]", function(e) {
    var action = this.dataset.action;

    if (action === "load-more" || action === "load-replace") {
        e.preventDefault();
        this.setAttribute("disabled", "disabled");
        this.innerHTML = "Loading...";
        var target = this.dataset.target;
        var that = this;
        $.ajax(this.href, {
            success: function(data) {
                that.outerHTML = "";

                if (action === "load-more") {
                    $(target).append($(data).find(target).html());

                } else if (action === "load-replace") {
                    $(target).html($(data).find(target).html());
                    window.historyInitiated = true;
                    history.pushState(null, null, that.href);
                }

            }
        });
    }
});
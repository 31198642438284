/*jshint esversion: 6 */

const hamburgerMenu = document.getElementById("hamburgerMenu");
const mainContent = document.getElementById("main");
const offCanvasMenu = document.getElementById("off-canvas-menu");
var touchMethod = ("ontouchstart" in window) ? "touchstart" : "click";

function closeNav() {
    document.body.classList.remove("is-expanded");
}

function openNav() {
    document.body.classList.add("is-expanded");
}

function toggleNav() {
    if (document.body.classList.contains("is-expanded")) {
        closeNav();
    } else {
        openNav();
    }
}

hamburgerMenu.addEventListener(touchMethod, () => {
    toggleNav();
}, true);

mainContent.addEventListener(touchMethod, (e) => {
    // Close mobile nav if click away from nav
    if (e.target != offCanvasMenu || e.target == hamburgerMenu) {
        closeNav();
    }
}, true);

document.addEventListener("keyup", (e) => {
    // Close mobile nav if "esc" is pressed
    if (e.keyCode === 27) {
        closeNav();
    }
}, true);